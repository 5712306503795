import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'billType' })

export class BillTypePipe implements PipeTransform {

    transform(type) {
        if (type == 'hr') return 'H.R.';
        if (type == 's') return 'S.';
        if (type == 'hres') return 'H.Res.';
        if (type == 'sres') return 'S.Res.';
        if (type == 'hjres') return 'H.J.Res.';
        if (type == 'sjres') return 'S.J.Res.';
        if (type == 'hconres') return 'H.Con.Res.';
        if (type == 'sconres') return 'S.Con.Res.';
    }
}