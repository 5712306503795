<mat-form-field appearance="fill" class="w80">
    <mat-label>Bill tags:</mat-label>
    <mat-chip-list #tagList [disabled]="disabled">
      <mat-chip
        *ngFor="let tag of tags"
        (removed)="remove(tag)">
        {{tag}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        #tagInput
        [formControl]="tagCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="tagList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
        {{tag}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>