import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from './core/pipes/pipes.module';
import { BillsTableModule } from './components/bills-table/bills-table.module';
import { BillTagsModule } from './components/bill-tags/bill-tags.module';
import { CongressSessionsModule } from './components/congress-sessions/congress-sessions.module';
import { AddBillModule } from './components/add-bill/add-bill.module';
import { AddActionModule } from './components/add-action/add-action.module';
import { BillTypePipe } from './core/pipes/bill-types.pipe';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AmplifyUIAngularModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    BillsTableModule,
    BillTagsModule,
    CongressSessionsModule,
    AddBillModule,
    AddActionModule
  ],
  providers: [BillTypePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
