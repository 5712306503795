<mat-progress-bar mode="indeterminate" *ngIf="searching"></mat-progress-bar>

<mat-horizontal-stepper>
    <mat-step label="Search for bill">
        <div [formGroup]="billParams">
            <mat-form-field appearance="fill" class="mr5">
                <mat-label>Select Congress:</mat-label>
                <mat-select formControlName="congress">
                    <mat-option *ngFor="let term of congressSessions" [value]="term.term">{{ term.term }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="mr5">
                <mat-label>Select Bill Type:</mat-label>
                <mat-select formControlName="billType">
                    <mat-option *ngFor="let type of billTypes" [value]="type.value">{{ type.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>Bill Number:</mat-label>
                <input matInput autocomplete="off" formControlName="billNumber">
            </mat-form-field>
        </div>
        <br>
        <button mat-flat-button color="primary" (click)="onBillSearch()"
            [disabled]="billParams.invalid || searching || searchError || billData || billExists">
            Search</button>
        <br>
        <ng-container *ngIf="searchError">
            <h2>Oops, there's been an error.</h2>
            <br>
            <button mat-flat-button color="warn" (click)="onReset()">Reset</button>
        </ng-container>
        <ng-container *ngIf="billData">
            <h2>BILL FOUND: {{ billData.billTitle }}</h2>
            <br>
            <button mat-flat-button color="primary" matStepperNext class="mr5">Continue</button>
            <button mat-flat-button color="warn" (click)="onReset()">Reset</button>
        </ng-container>
        <ng-container *ngIf="billExists">
            <h2>Bill already exists.</h2>
            <br>
            <button mat-flat-button color="warn" (click)="onReset()">Reset</button>
        </ng-container>
    </mat-step>

    <mat-step label="Add bill details">
        <div [formGroup]="billDetails">
            <mat-form-field appearance="fill" class="w80">
                <mat-label>Bill Title:</mat-label>
                <input matInput autocomplete="off" formControlName="billTitle">
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill" class="w80">
                <mat-label>Public Description:</mat-label>
                <textarea rows="5" matInput autocomplete="off" formControlName="publicDescription"></textarea>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill" class="w80">
                <mat-label>Private Notes:</mat-label>
                <textarea rows="5" matInput autocomplete="off" formControlName="privateNotes"></textarea>
            </mat-form-field>
            <br>
            <mat-form-field appearance="fill" class="mr5">
                <mat-label>Display on Web?</mat-label>
                <mat-select formControlName="displayWeb">
                    <mat-option value=true>Yes</mat-option>
                    <mat-option value=false>No</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill">
                <mat-label>NUSA Position:</mat-label>
                <mat-select formControlName="nusaPosition">
                    <mat-option value=1>Support</mat-option>
                    <mat-option value=0>Oppose</mat-option>
                    <mat-option value=2>Mixed</mat-option>
                    <mat-option value=3>Undecided</mat-option>
                    <mat-option value=4>Not Grading</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <app-bill-tags (billTagEvent)="billDetails.patchValue({tags: $event})"></app-bill-tags>
        <br>
        <button mat-flat-button color="primary" [disabled]="billDetails.invalid" matStepperNext>Continue</button>
    </mat-step>

    <mat-step label="Save & Confirm">
        <ng-container *ngIf="billParams.valid && billDetails.valid">
        <h4>Bill Title:</h4>
        <p class="confirm">{{ billParams.value.billType | billType }} {{ billParams.value.billNumber }}: {{ billDetails.value.billTitle }}</p>
        <h4>Bill Sponsor:</h4>
        <p class="confirm">{{ billData.sponsorName }}</p>
        <h4>Introduced Date:</h4>
        <p class="confirm">{{ billData.introducedDate | date:'mediumDate' }}</p>
        <h4>Public Description:</h4>
        <p class="confirm">{{ billDetails.value.publicDescription }}</p>
        <h4>Private Notes:</h4>
        <p class="confirm">{{ billDetails.value.privateNotes }}</p>
        <h4>Display on Web:</h4>
        <p class="confirm">{{ billDetails.value.displayWeb }}</p>
        <h4>NumbersUSA Position:</h4>
        <p class="confirm">{{ billDetails.value.nusaPosition | nusaPosition }}</p>
        <h4>Bill Tags:</h4>
        <ul>
            <li *ngFor="let tag of billDetails.value.tags">{{ tag }}</li>
        </ul>
        <button mat-flat-button color="primary" (click)="onSave()" [disabled]="searching || billSaved || billSavedError">Save Bill</button>
    </ng-container>
    <ng-container *ngIf="billSaved">
        <h2>Bill successfully saved!</h2>
        <button mat-flat-button color="primary" (click)="onReload()" class="mr5">Add another bill</button>
        <!-- <button mat-flat-button color="primary">Home</button> -->
    </ng-container>
    <ng-container *ngIf="billSavedError">
        <h2>Oops, there's been an error.</h2>
        <button mat-flat-button color="warn" (click)="onReload()" class="mr5">Try Again</button>
        <!-- <button mat-flat-button color="primary">Home</button> -->
    </ng-container>
    </mat-step>
</mat-horizontal-stepper>