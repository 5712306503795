import { Component, OnInit, ViewChild } from '@angular/core';
import { APIService } from 'src/app/API.service';

@Component({
  selector: 'app-bills-table',
  templateUrl: './bills-table.component.html',
  styleUrls: ['./bills-table.component.css']
})
export class BillsTableComponent implements OnInit {

  hidden = -1;
  bills: any;
  tags: any;
  actions: any;
  billTypes: any;
  congressSessions = [];
  selectedCongress: number;
  selectedBillType: string;
  noBill: boolean = false;

  constructor(private api: APIService) { }

  ngOnInit(): void {
    this.getActiveSession();
    this.api.OnUpdateBillsListener.subscribe(
      (event: any) => {
        let updatedBill = event.value.data.onUpdateBills;
        for (let i = 0; i < this.bills.length; i++) {
          if (this.bills[i].id == updatedBill.id) {
            this.bills[i] = updatedBill;
            break;
          }
        }
      });
  }

  getActiveSession() {
    this.api.ListCongressSessionss().then((event: any) => {
      let term = event.items;
      this.getBills(term);
      for (let i=0; i<term.length;i++) {
        this.congressSessions.push(term[i].term);
      }
      this.congressSessions = this.congressSessions.sort((a,b) => b-a);
    }).catch(e => console.log('Error getting active session... ', e));
  }

  getBills(term) {
    let currentTerm: number;
    for (let i=0;i<term.length;i++) {
      if (term[i].isDefault) {
        currentTerm = term[i].term;
        break;
      }
    }
    this.api.ListBillss({congress: {eq: currentTerm}}, 1000).then(event => {
      this.bills = event.items;
      this.bills.length==0 ? this.noBill=true : this.noBill = false;
      this.billTypes = [...new Set(this.bills.map(a => a.billType))];
      this.bills.sort((a, b) => a.billType.localeCompare(b.billType) || a.billNumber - b.billNumber);
    }).catch(e => console.log('Error getting bills... ', e));
  }

  onSave(item) {
    let edits = {
      id: item.id,
      billTitle: item.billTitle,
      publicDescription: item.publicDescription,
      privateNotes: item.privateNotes,
      gradingNotes: item.gradingNotes,
      displayWeb: item.displayWeb,
      nusaPosition: item.nusaPosition,
      tags: item.tags
    }
    this.api.UpdateBills(edits).catch(event => {
      event.target.blur();
      console.log('Bill updated.');
    }).catch(e => console.log('Error updating bill... ', e));
  }

  onSelectCongress() {
    this.noBill = false;
    this.bills = [];
    this.api.BillsByCongress(this.selectedCongress,undefined,undefined,1000).then(event => {
      this.bills = event.items;
      this.bills.length==0 ? this.noBill=true : this.noBill = false;
      this.bills.sort((a, b) => a.billType.localeCompare(b.billType) || a.billNumber - b.billNumber);
    }).catch(e => console.log('Error getting bills... ',e));
  }


}