<amplify-authenticator>
  <amplify-sign-in slot="sign-in" hide-sign-up="true">
    <div slot="secondary-footer-content"></div>
  </amplify-sign-in>
  <ul>
    <li><a routerLink="/list-bills">List Bills</a></li>
    <li><a routerLink="/add-bill">Add Bill</a></li>
    <router-outlet></router-outlet>
  </ul>
  <amplify-sign-out></amplify-sign-out>
</amplify-authenticator>


<!-- <router-outlet></router-outlet> -->
