import { Component, EventEmitter, Input, OnInit, Output, ElementRef, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { APIService } from 'src/app/API.service';
import { Tags } from 'src/app/core/types/tags';

@Component({
  selector: 'app-bill-tags',
  templateUrl: './bill-tags.component.html',
  styleUrls: ['./bill-tags.component.css']
})
export class BillTagsComponent implements OnInit {

  @Output() billTagEvent = new EventEmitter(); // Emits list of selected tags each time list is updated
  @Output() allTagsEvent = new EventEmitter(); // Emits list of all tags in library when first loaded
  @Input() billTagData: any; // Allows parent component to inject a list of selected tags
  @Input() disabled; // Allows parent component to control whether the form is disabled

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredTags: Observable<string[]>;
  tags: string[] = []; // selected tags
  allTags: string[] = []; // tags library
  tagsLibrary: Array<Tags>; // array from the tags library

  constructor(private api: APIService) { }

  ngOnInit(): void {
    // Inject tags variable with pre-selected tags and emit
    if (this.billTagData!=null) {
      this.tags = this.billTagData;
    }
    this.billTagEvent.emit(this.tags);

    // get tags library from db and store tagName values as an object
    this.api.ListTagsLibrarys().then(event => {
      this.tagsLibrary = event.items;
      this.tagsLibrary.sort((a,b) => a.tagName > b.tagName ? 1 : -1);
      for (let i=0; i<this.tagsLibrary.length;i++) {
        this.allTags.push(this.tagsLibrary[i].tagName);
      }
      this.allTagsEvent.emit(this.allTags);
    }).catch(e => {
      console.log(e);
    });
    setTimeout(a => 
      this.filteredTags = this.tagCtrl.valueChanges.pipe(
        startWith(null),
        map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice())),
        1000);
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.tags.push(value.trim());
      this.billTagEvent.emit(this.tags);
    }

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.billTagEvent.emit(this.tags);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tags.push(event.option.viewValue);
    this.billTagEvent.emit(this.tags);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

}
