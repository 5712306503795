import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CongressSessionsComponent } from './congress-sessions/congress-sessions.component';
import { FormsModule } from '@angular/forms';

import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [CongressSessionsComponent],
  imports: [
    CommonModule,
    FormsModule,
    MatTabsModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule
  ]
})
export class CongressSessionsModule { }
