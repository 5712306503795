import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { APIService } from 'src/app/API.service';
import { ApiGatewayService } from 'src/app/core/services/api-gateway.service';
import { BillTagsComponent } from '../../bill-tags/bill-tags/bill-tags.component';

@Component({
  selector: 'app-add-bill',
  templateUrl: './add-bill.component.html',
  styleUrls: ['./add-bill.component.css']
})
export class AddBillComponent implements OnInit {

  @ViewChild(MatStepper) stepper;
  @ViewChild(BillTagsComponent) billTags;

  searching: boolean = false;
  congressSessions: any;
  billTypes: any;
  billExists: boolean = false;
  searchError: boolean = false;
  billData: any;
  billSaved: boolean = false;
  billSavedError: boolean = false;

  billParams = new FormGroup({
    congress: new FormControl('', Validators.required),
    billType: new FormControl('', Validators.required),
    billNumber: new FormControl('', Validators.required),
  });

  billDetails = new FormGroup({
    billTitle: new FormControl('', Validators.required),
    publicDescription: new FormControl(''),
    privateNotes: new FormControl(''),
    displayWeb: new FormControl('', Validators.required),
    nusaPosition: new FormControl('', Validators.required),
    tags: new FormControl('')
  });

  constructor(private api: APIService,
    private ag: ApiGatewayService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.api.ListBillTypess().then(event => {
      this.billTypes = event.items;
      this.billTypes = this.billTypes.sort((a,b) => a.orderBy-b.orderBy);
    }).catch(e => console.log('Error loading bill types... ', e));
    this.api.ListCongressSessionss().then(event => {
      this.congressSessions = event.items;
      this.congressSessions = this.congressSessions.sort((a,b) => b.term-a.term);
    }).catch(e => console.log('Error loading bill types... ', e));
  }

  onReset() {
    this.billParams.reset();
    this.billExists = false;
    this.searchError = false;
    this.billData = null;
  }

  onBillSearch() {
    this.searching = true;
    this.api.ListBillss({
      congress: {eq: this.billParams.value.congress},
      billType: {eq: this.billParams.value.billType},
      billNumber: {eq: this.billParams.value.billNumber}
    }).then(event => {
      if (event.items.length > 0) {
        this.billExists = true;
        this.searching = false;
      } else {
        this.http.get(this.ag.apiUrl + 'get-new-bill', {
          params: {
            congress: this.billParams.value.congress,
            billType: this.billParams.value.billType,
            billNumber: this.billParams.value.billNumber
          },
          headers: {
            'x-api-key': this.ag.apiKey
          }
        }).subscribe(event => {
          this.billData = event;
          this.billDetails.patchValue({billTitle: this.billData.billTitle});
          this.searching = false;
        },
        error => {
          console.log('Error bill searching... ', error);
          this.searchError = true;
          this.searching = false;
        });
      }
    }).catch(e => {
      console.log('Error checking if bill exists... ', e);
      this.searchError = true;
      this.searching = false;
    });
  }

  onSave() {
    this.searching = true;
    this.api.CreateBills({
      congress: this.billParams.value.congress,
      billType: this.billParams.value.billType,
      billNumber: this.billParams.value.billNumber,
      billTitle: this.billDetails.value.billTitle,
      sponsorName: this.billData.sponsorName,
      sponsorBioguideId: this.billData.sponsorBioguideId,
      introducedDate: this.billData.introducedDate,
      totalCosponsors: this.billData.totalCosponsors,
      publicDescription: this.billDetails.value.publicDescription,
      privateNotes: this.billDetails.value.privateNotes,
      displayWeb: this.billDetails.value.displayWeb,
      nusaPosition: this.billDetails.value.nusaPosition,
      tags: this.billDetails.value.tags
    }).then(event => {
      this.billSaved = true;
      this.searching = false;
    }).catch(e => {
      this.billSavedError = true;
      console.log('Error saving bill... ', e);
      this.searching = false;
    });
  }

  onReload() {
    this.stepper.reset();
    this.billParams.reset();
    this.billDetails.reset();
    this.billExists = false;
    this.searchError = false;
    this.billData = null;
    this.billSaved = false;
    this.billSavedError = false;
    this.billTags.tags = [];
  }

}
