import { Component, OnInit } from '@angular/core';
import { a } from '@aws-amplify/ui';
import { APIService } from 'src/app/API.service';

@Component({
  selector: 'app-congress-sessions',
  templateUrl: './congress-sessions.component.html',
  styleUrls: ['./congress-sessions.component.css']
})
export class CongressSessionsComponent implements OnInit {

  congressSessions: any;
  isDefault: any;

  constructor(private api: APIService) { }

  ngOnInit(): void {
    this.api.ListCongressSessionss().then(event => {
      this.congressSessions = event.items;
      this.congressSessions = this.congressSessions.sort((a,b) => b.term-a.term);
      for (let i=0;i<event.items.length;i++) {
        if (event.items[i].isDefault) {
          this.isDefault = event.items[i].term;
          break;
        }
      }
    }).catch(e => console.log('Error loading congress sessions... ', e));
  }

}
