import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NusaPositionIconsComponent } from './nusa-position-icons/nusa-position-icons.component';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [NusaPositionIconsComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [NusaPositionIconsComponent]
})
export class NusaPositionIconsModule { }
