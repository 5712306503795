import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { APIService } from 'src/app/API.service';

@Component({
  selector: 'app-grade-rules',
  templateUrl: './grade-rules.component.html',
  styleUrls: ['./grade-rules.component.css']
})
export class GradeRulesComponent implements OnInit {

  @Output() gradeRuleEvent = new EventEmitter();
  @Input() actionSponsorData;

  gradeRule = new FormGroup({
    gradeTitle: new FormControl('', Validators.required),
    gradeDescription: new FormControl('', Validators.required),
    category: new FormControl('', Validators.required),
    goodBad: new FormControl('', Validators.required),
    points: new FormControl('', Validators.required),
    applyToAction: new FormControl('')
  });

  gradeRules = [];
  gradeCategories: any;


  constructor(private api: APIService) { }

  ngOnInit(): void {
    this.api.ListGradeCategoriess({isActive: {eq: true}}).then(event => {
      this.gradeCategories = event.items;
      this.gradeCategories = this.gradeCategories.sort((a,b) => a.orderBy-b.orderBy);
    }).catch(e => console.log('Error fetching grade categories... ', e));
  }

  onAddRule() {
    this.gradeRules.push(this.gradeRule.value);
    this.gradeRuleEvent.emit(this.gradeRules);
    this.gradeRule.reset();
  }

  onDelete(index) {
    this.gradeRules.splice(index,1);
  }
}
