import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddActionComponent } from './components/add-action/add-action/add-action.component';
import { AddBillComponent } from './components/add-bill/add-bill/add-bill.component';
import { BillsTableComponent } from './components/bills-table/bills-table/bills-table.component';
import { CongressSessionsComponent } from './components/congress-sessions/congress-sessions/congress-sessions.component';

const routes: Routes = [
  {
    path: 'list-bills',
    component: BillsTableComponent
  },
  { 
    path: '',
    redirectTo: '/add-action',
    pathMatch: 'full' 
  },
  {
    path: 'congress-sessions',
    component: CongressSessionsComponent
  },
  {
    path: 'add-bill',
    component: AddBillComponent
  },
  {
    path: 'add-action',
    component: AddActionComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {'onSameUrlNavigation': 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
