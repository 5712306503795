import { Component, OnInit } from '@angular/core';
import { APIService } from './API.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(private api: APIService) { }

  ngOnInit() {
    // this.api.BillsByCongress(116,undefined,undefined,3).then(e => console.log(e)).catch(e => console.log(e));
    // this.api.CreateMemberActions({
    //   bioguideId: 'A12345',
    //   actionId: '1252561234',
    //   actionTaken: Number("1"),
    //   isGrading: Number("1"),
    //   actionDate: '2020-01-01',
    //   notes: ''
    // }).then(e => console.log(e)).catch(e => console.log(e));
  }

}