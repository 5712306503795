import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nusaPosition' })

export class NUSAPositionPipe implements PipeTransform {

    transform(type) {
        if (type == 0) return 'Oppose';
        if (type == 1) return 'Support';
        if (type == 2) return 'Mixed';
        if (type == 3) return 'Undecided';
        if (type == 4) return 'Not Grading';
    }
}