<div style="overflow-anchor: auto;">
    <ng-container *ngIf="congressSessions.length > 0">
        <mat-form-field appearance="fill" class="mr5">
            <mat-label>Select Congress:</mat-label>
            <mat-select [(ngModel)]="selectedCongress">
                <mat-option *ngFor="let term of congressSessions" [value]="term">{{ term }}</mat-option>
            </mat-select>
        </mat-form-field>
        <button mat-flat-button color="primary" (click)="onSelectCongress()" class="mr5">Select Congress</button>
    </ng-container>

    <ng-container style="border-bottom: 1px solid lightgray;">
        <ng-container *ngFor="let item of bills; let i=index" style="margin: auto; position: relative;">
            <button class="button" (click)="hidden==i ? hidden=-1 : hidden=i">
                <div class="col-3">{{ item.congress }}</div>
                <div class="col-5">{{ item.billType | billType }}</div>
                <div class="col-5">{{ item.billNumber }}</div>
                <div class="col-25" [class.red-text]="item.privateNotes==''">{{ item.billTitle }}</div>
                <div class="col-20">{{ item.sponsorName }}</div>
                <div class="col-10">{{ item.introducedDate | date:'mediumDate' }}</div>
                <div class="col-5">{{ item.totalCosponsors }}</div>
                <div class="col-5">
                    <app-nusa-position-icons [nusaPosition]="item.nusaPosition"></app-nusa-position-icons>
                </div>
                <span style="float: right; font-size: 1.5em;" *ngIf="hidden">+</span>
                <span style="float: right; font-size: 1.5em;" *ngIf="!hidden">-</span>
            </button>
            <div [class.expanded]="hidden==i" class="hidden">
                <app-edit-bill [item]="item" (saveEvent)="onSave($event)" *ngIf="hidden==i"></app-edit-bill>
            </div>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="noBill">
        <h3>No bills found.</h3>
    </ng-container>
</div>