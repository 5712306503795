<mat-dialog-content>


    <h2>Actions for {{ data.billData.billType | billType }} {{ data.billData.billNumber }}: {{ data.billData.billTitle
        }}</h2>
    <ng-container *ngIf="actionsList != null">
        <ul>
            <li *ngFor="let action of actionsList">{{ action.actionTitle }}</li>
        </ul>
    </ng-container>
    <ng-container *ngIf="actionsList == 0">
        There are no actions.
    </ng-container>

    <br>
    <mat-divider></mat-divider>
    <br>
    
    <mat-form-field appearance="fill">
        <mat-label>Action Type:</mat-label>
        <mat-select [(ngModel)]="actionType">
            <mat-option value="sponsorship">Sponsorship</mat-option>
            <mat-option value="roll call">Roll Call</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-divider></mat-divider>

    <app-add-sponsorship *ngIf="actionType=='sponsorship'" [billData]="data.billData"></app-add-sponsorship>
    <app-add-roll-call *ngIf="actionType=='roll call'" [billData]="data.billData"></app-add-roll-call>

</mat-dialog-content>