import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BillTypePipe } from './bill-types.pipe';
import { NUSAPositionPipe } from './nusa-position.pipe';


@NgModule({
  declarations: [BillTypePipe, NUSAPositionPipe],
  imports: [CommonModule],
  exports: [BillTypePipe, NUSAPositionPipe]
})
export class PipesModule { }
