/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://r36ri6gjbjeefmqgjhvd5dkjgq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-uug2uwgcfvd4ndcj4el7derrki",
    "aws_cognito_identity_pool_id": "us-east-1:ce4d9b15-0133-413d-a622-aeb8ff3bb5e7",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_01S7Kmf8Y",
    "aws_user_pools_web_client_id": "5s1fd0dna70l72mig0egonequo",
    "oauth": {
        "domain": "grades04e0ddf3-04e0ddf3-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.nusa-grades.com/",
        "redirectSignOut": "https://www.nusa-grades.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
