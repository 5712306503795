<mat-tab-group>
    <mat-tab label="Add Session">
        ADD FORM HERE
        <mat-divider></mat-divider>
        <div *ngFor="let term of congressSessions" [class.isDefault]="term.isDefault">{{ term.term }}</div>
    </mat-tab>
    <mat-tab label="Change Default Session">
        <mat-form-field appearance="fill">
            <mat-label>Set default Congress:</mat-label>
            <mat-select [(ngModel)]="isDefault">
                <mat-option *ngFor="let term of congressSessions" [value]="term.term">{{ term.term }}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-tab>
    <mat-tab label="Set Recent Congress">
        <mat-form-field>
            <mat-label>Set default Congress:</mat-label>
            <mat-select [(ngModel)]="isDefault">
                <mat-option *ngFor="let term of congressSessions" [value]="term.term">{{ term.term }}</mat-option>
            </mat-select>
        </mat-form-field>
    </mat-tab>
  </mat-tab-group>