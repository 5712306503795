<div [formGroup]="gradeRule">
    <mat-form-field appearance="fill" class="w80">
        <mat-label>Grade Title:</mat-label>
        <input matInput formControlName="gradeTitle">
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="w80">
        <mat-label>Grade Description:</mat-label>
        <textarea matInput formControlName="gradeDescription" rows="5"></textarea>
    </mat-form-field>
    <br>
    <mat-form-field appearance="fill" class="mr5">
        <mat-label>Select category:</mat-label>
        <mat-select formControlName="category">
            <mat-option *ngFor="let cat of gradeCategories" [value]="cat.shortName">{{ cat.shortName }}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mr5">
        <mat-label>Good/Bad Pts:</mat-label>
        <mat-select formControlName="goodBad">
            <mat-option value="good">Good</mat-option>
            <mat-option value="bad">Bad</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="mr5">
        <mat-label>Points:</mat-label>
        <input matInput formControlName="points" autocomplete="off">
    </mat-form-field>
    <button mat-flat-button color="primary" [disabled]="gradeRule.invalid" (click)="onAddRule()">Add Rule</button>
</div>

<mat-divider></mat-divider>

<ng-container *ngIf="gradeRules.length==0">
    <h3>No Rules have been added.</h3>
</ng-container>

<ng-container *ngIf="gradeRules.length>0">
    <ul>
        <li *ngFor="let rule of gradeRules; let i=index">
            {{ rule.points }} {{ rule.goodBad }} points in {{ rule.category }} <span (click)="onDelete(i)" class="delete">delete</span>
        </li>
    </ul>
</ng-container>