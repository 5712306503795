import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { APIService } from 'src/app/API.service';

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.css']
})
export class AddActionComponent implements OnInit {

  actionsList: any;
  actionType: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {billData}, private api: APIService) { }

  ngOnInit(): void {
    this.api.BillActions(this.data.billData.id).then((event:any) => {
      event ? this.actionsList = event.items : this.actionsList = 0;
    }).catch(e => console.log('Error loading bill actions... ',e));
  }

}
