<mat-progress-bar mode="indeterminate" *ngIf="searching"></mat-progress-bar>

<mat-horizontal-stepper *ngIf="billData != null">
    <mat-step label="Search for Votes">
        <h2>Search for rollcall votes:</h2>
        <button mat-flat-button color="primary" (click)="onSearchActions()"
            [disabled]="searching || actionsList != null || getBillActionsError">Search</button>
            <br>
        <ng-container *ngIf="actionsList != null">
            <mat-form-field appearance="fill" style="width: 80%">
                <mat-select [(ngModel)]="selectedAction">
                    <mat-option *ngFor="let action of actionsList">
                        <strong>{{ action.chamber }}:</strong> {{ action.fullActionName }} ({{ action.date |
                        date:'medium' }})
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br>
            <button mat-flat-button color="primary" matStepperNext>Continue</button>
        </ng-container>
        <ng-container *ngIf="getBillActionsError">
            <h4>Oops, there's been an error.</h4>
            <button mat-dialog-close mat-flat-button style="background-color: lightgray">Close</button>
        </ng-container>
    </mat-step>
    <!-- <mat-step label="Add Action Details">
        <h3>{{ billData.billType | billType }} {{ billData.billNumber }}: {{ billData.billTitle }}</h3>
        <mat-form-field appearance="fill" class="w80">
            <mat-label>Action Title:</mat-label>
            <input matInput [formControl]="actionTitle">
        </mat-form-field>
        <br>
        <button mat-flat-button color="primary" matStepperNext [disabled]="actionTitle.invalid"
            (click)="onGetRollCall()">Search for roll call</button>
    </mat-step> -->













    <!-- <mat-step label="Confirm sponsors">
        <ng-container *ngIf="getSponsorsError">
            <h4>Oops, there's been an error.</h4>
            <button mat-flat-button color="primary" matStepperPrevious>Try again</button>
        </ng-container>
        <ng-container *ngIf="sponsorData!=null && !getSponsorsError">
            <h3>BILL SPONSOR: {{ sponsorData.sponsor.fullName }}</h3>
            <mat-divider></mat-divider>
            <ng-container *ngIf="sponsorData.cosponsors.length == 0">
                <h4>There are no cosponsors.</h4>
            </ng-container>
            <ng-container *ngIf="sponsorData.cosponsors.length > 0">
                <h4>{{ sponsorData.cosponsors.length }} cosponsors found</h4>
                <div *ngFor="let item of sponsorData.cosponsors">
                    <ul>
                        <li class="w25">{{ item.fullName }}</li>
                        <li class="w5">
                            <mat-checkbox [checked]="item.isGrading==1"
                                (change)="item.isGrading==1 ? item.isGrading=0 : item.isGrading=1">
                            </mat-checkbox>
                        </li>
                        <li>
                            <mat-form-field appearance="fill">
                                <mat-label>Notes:</mat-label>
                                <input matInput [(ngModel)]="item.notes">
                            </mat-form-field>
                        </li>
                    </ul>
                    <mat-divider></mat-divider>
                </div>
            </ng-container>
            <br>
            <button mat-flat-button matStepperNext color="primary">Add Grade Rules</button>
        </ng-container>
    </mat-step>
    <mat-step label="Add grade rules">
        <button style="float:right;" mat-mini-fab color="primary" (click)="onPrefill()">
            <mat-icon>edit</mat-icon>
        </button>
        <p><strong>PRIVATE NOTES: </strong><em>{{ billData.gradingNotes }}</em></p>
        <app-grade-rules [actionSponsorData]="billData" (gradeRuleEvent)="gradeRules=$event"></app-grade-rules>
        <button mat-flat-button color="primary" matStepperNext>Confirm & Save</button>
    </mat-step>
    <mat-step label="Confirm & save">
        <h3>Adding Sponsorship Action for {{ billData.billType | billType }} {{ billData.billNumber }}: {{
            billData.billTitle }}</h3>
        <button [disabled]="searching || actionSaved || saveError || saveSponsorError || saveCosponsorsError"
            mat-flat-button color="primary" (click)="onSave()">Save Action</button>
        <ng-container *ngIf="actionSaved">
            <h4>Action saved successfully!</h4>
        </ng-container>
        <ng-container *ngIf="saveError">
            <h4>Oops, there was an error saving the action.</h4>
        </ng-container>
        <ng-container *ngIf="saveSponsorError">
            <h4>Oops, there was an error saving the Sponsor.</h4>
        </ng-container>
        <ng-container *ngIf="saveCosponsorsError">
            <h4>Oops, there was an error saving one or more of the Cosponsors.</h4>
        </ng-container>
    </mat-step> -->
</mat-horizontal-stepper>