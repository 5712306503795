import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { APIService } from 'src/app/API.service';
import { BillTypePipe } from 'src/app/core/pipes/bill-types.pipe';
import { ApiGatewayService } from 'src/app/core/services/api-gateway.service';
import { GradeRulesComponent } from '../grade-rules/grade-rules.component';

@Component({
  selector: 'app-add-sponsorship',
  templateUrl: './add-sponsorship.component.html',
  styleUrls: ['./add-sponsorship.component.css']
})
export class AddSponsorshipComponent implements OnInit {

  @Input() billData;
  @ViewChild(GradeRulesComponent) grades;
  searching: boolean = false;
  actionTitle = new FormControl('', Validators.required);
  getSponsorsError: boolean = false;
  sponsorData: any;
  gradeRules: any;
  actionSaved: boolean = false;
  saveError: boolean = false;
  saveSponsorError: boolean = false;
  saveCosponsorsError: boolean = false;

  constructor(private api: APIService,
    private http: HttpClient,
    private ag: ApiGatewayService,
    private bt: BillTypePipe) { }

  ngOnInit(): void {
    let billType = this.bt.transform(this.billData.billType);
    this.actionTitle.setValue(`Sponsored ${billType} ${this.billData.billNumber}, ${this.billData.billTitle}`);
  }

  onGetSponsors() {
    this.searching = true;
    this.http.get(this.ag.apiUrl + 'get-cosponsors', {
      params: {
        congress: this.billData.congress,
        billNumber: this.billData.billNumber,
        billType: this.billData.billType
      },
      headers: {
        'x-api-key': this.ag.apiKey
      }
    }).subscribe((data) => {
      this.sponsorData = data;
      this.searching = false;
    },
      (error) => {
        console.log('Error fetching cosponsors... ', error);
        this.getSponsorsError = true;
      });
  }

  onPrefill() {
    this.grades.gradeRule.patchValue({
      gradeTitle: this.actionTitle.value,
      gradeDescription: this.billData.publicDescription
    });
  }

  onSave() {
    this.searching = true;
    for (let i=0; i<this.gradeRules.length;i++) {
      this.gradeRules[i].applyToAction = 1;
    }
    this.api.CreateAction({
      billId: this.billData.id,
      actionTitle: this.actionTitle.value,
      chamber: this.sponsorData.chamber,
      congress: this.billData.congress,
      actionType: "Sponsorship",
      actionDate: this.sponsorData.actionDate,
      gradeRules: this.gradeRules
    }).then(event => {
      this.actionSaved = true;
      for (let i=0;i<this.sponsorData.cosponsors.length;i++) {
        this.api.CreateMemberActions({
          bioguideId: this.sponsorData.cosponsors[i].bioguideId,
          billId: this.billData.id,
          actionId: event.id,
          actionTaken: Number(this.sponsorData.cosponsors[i].actionTaken),
          isGrading: Number(this.sponsorData.cosponsors[i].isGrading),
          actionDate: this.sponsorData.cosponsors[i].actionDate,
          notes: this.sponsorData.cosponsors[i].notes
        }).then(e => this.searching = false).catch(e => {
          console.log(`Error saving action for ${this.sponsorData.cosponsors[i].bioguideId}... `, e);
          this.saveCosponsorsError = true;
          this.searching = false;
      });
      }
      this.api.CreateMemberActions({
        bioguideId: this.sponsorData.sponsor.bioguideId,
        billId: this.billData.id,
        actionId: event.id,
        actionTaken: Number(this.sponsorData.sponsor.actionTaken),
        isGrading: Number(this.sponsorData.sponsor.isGrading),
        actionDate: this.sponsorData.sponsor.actionDate,
        notes: this.sponsorData.sponsor.notes
      }).catch(e => {
        this.saveSponsorError = true;
        this.searching = false;
        console.log(`Error saving action for ${this.sponsorData.sponsor.bioguideId}... `, e)
      });
    }).catch(e => {
      this.saveError = true;
      this.searching = false;
      console.log('Error saving action... ', e);
    });
  }
}