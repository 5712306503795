import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiGatewayService {

  apiUrl = 'https://qxqnbsjoci.execute-api.us-east-1.amazonaws.com/v1/';
  apiKey = 'X1KvYKUD0q38LJrlxkLmW3041h1VrcEh9aBpcrh4';
  

  constructor() { }
}
