<p><a [href]="billLink" target="_blank">{{ billLink }}</a></p>

<div style="float:right">
        <button mat-mini-fab color="warn" style="margin-right: 2px;" (click)="openDialog()">
            <mat-icon>directions_run</mat-icon>
        </button>
        <button mat-mini-fab color="primary">
            <mat-icon>article</mat-icon>
        </button>
    </div>

    <mat-form-field appearance="fill" class="w80">
        <mat-label>Bill title:</mat-label>
        <input matInput [(ngModel)]="item.billTitle" [readonly]="!edit.billTitle">
    </mat-form-field>
    <span class="save" *ngIf="edit.billTitle" (click)="saveEvent.emit(item);edit.billTitle=false">save</span>
    <span class="edit" *ngIf="!edit.billTitle" (click)="edit.billTitle=true">edit</span>
    <span class="close" *ngIf="edit.billTitle" (click)="edit.billTitle=false">close</span>
    <br>
    <mat-form-field appearance="fill" class="w80">
        <mat-label>Public Description:</mat-label>
        <textarea matInput [(ngModel)]="item.publicDescription" rows="5" [readonly]="!edit.publicDescription"></textarea>
    </mat-form-field>
    <span class="save" *ngIf="edit.publicDescription" (click)="saveEvent.emit(item);edit.publicDescription=false">save</span>
    <span class="edit" *ngIf="!edit.publicDescription" (click)="edit.publicDescription=true">edit</span>
    <span class="close" *ngIf="edit.publicDescription" (click)="edit.publicDescription=false">close</span>
    <br>
    <!-- <div [formGroup]="billData"> -->
    <mat-form-field appearance="fill" class="w80">
        <mat-label>Private Notes:</mat-label>
        <textarea matInput rows="5" [(ngModel)]="item.privateNotes" [readonly]="!edit.privateNotes"></textarea>
    </mat-form-field>
    <span class="save" *ngIf="edit.privateNotes" (click)="saveEvent.emit(item);edit.privateNotes=false">save</span>
    <span class="edit" *ngIf="!edit.privateNotes" (click)="edit.privateNotes=true">edit</span>
    <span class="close" *ngIf="edit.privateNotes" (click)="edit.privateNotes=false">close</span>
    <!-- </div> -->
    <br>
    <mat-form-field appearance="fill" class="w80">
        <mat-label>Grading Notes:</mat-label>
        <textarea matInput [(ngModel)]="item.gradingNotes" rows="5" [readonly]="!edit.gradingNotes"></textarea>
    </mat-form-field>
    <span class="save" *ngIf="edit.gradingNotes" (click)="saveEvent.emit(item);edit.gradingNotes=false">save</span>
    <span class="edit" *ngIf="!edit.gradingNotes" (click)="edit.gradingNotes=true">edit</span>
    <span class="close" *ngIf="edit.gradingNotes" (click)="edit.gradingNotes=false">close</span>
    <br>
    <mat-form-field appearance="fill">
        <mat-label>Display on Web:</mat-label>
        <mat-select [(ngModel)]="item.displayWeb" [disabled]="!edit.displayWeb">
            <mat-option [value]=true>Yes</mat-option>
            <mat-option [value]=false>No</mat-option>
        </mat-select>
    </mat-form-field>
    <span class="save" *ngIf="edit.displayWeb" (click)="saveEvent.emit(item);edit.displayWeb=false">save</span>
    <span class="edit" *ngIf="!edit.displayWeb" (click)="edit.displayWeb=true">edit</span>
    <span class="close" *ngIf="edit.displayWeb" (click)="edit.displayWeb=false">close</span>
    <mat-form-field appearance="fill">
        <mat-label>NumbersUSA Position:</mat-label>
        <mat-select [(ngModel)]="item.nusaPosition" [disabled]="!edit.nusaPosition">
            <mat-option [value]=1>Support</mat-option>
            <mat-option [value]=0>Oppose</mat-option>
            <mat-option [value]=2>Mixed</mat-option>
            <mat-option [value]=3>Undecided</mat-option>
            <mat-option [value]=4>Not Grading</mat-option>
        </mat-select>
    </mat-form-field>
    <span class="save" *ngIf="edit.nusaPosition" (click)="saveEvent.emit(item);edit.nusaPosition=false">save</span>
    <span class="edit" *ngIf="!edit.nusaPosition" (click)="edit.nusaPosition=true">edit</span>
    <span class="close" *ngIf="edit.nusaPosition" (click)="edit.nusaPosition=false">close</span>
    <br>
    <ng-container *ngIf="edit.tags">
        <app-bill-tags [billTagData]="item.tags" (billTagEvent)="item.tags=$event"></app-bill-tags>
        <span class="save" (click)="saveEvent.emit(item);edit.tags=false">save</span>
        <span class="close" (click)="edit.tags=false">close</span>
    </ng-container>
    <ng-container *ngIf="!edit.tags">
        <strong>Bill Tags:</strong>
        <mat-chip-list>
            <mat-chip *ngFor="let tag of item.tags">{{tag}}</mat-chip>
            <span class="edit" *ngIf="!edit.tags" (click)="edit.tags=true">edit</span>
        </mat-chip-list>
    </ng-container>
    