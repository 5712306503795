import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillsTableComponent } from './bills-table/bills-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../core/pipes/pipes.module';
import { NusaPositionIconsModule } from '../nusa-position-icons/nusa-position-icons.module';
import { MatButtonModule } from '@angular/material/button';
import { BillTagsModule } from '../bill-tags/bill-tags.module';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { EditBillComponent } from './edit-bill/edit-bill.component';
import { MatChipsModule } from '@angular/material/chips';



@NgModule({
  declarations: [BillsTableComponent, EditBillComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    PipesModule,
    MatButtonModule,
    NusaPositionIconsModule,
    BillTagsModule,
    MatDividerModule,
    MatDialogModule,
    MatChipsModule
  ],
  exports: [BillsTableComponent]
})
export class BillsTableModule { }
