import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradeRulesComponent } from './grade-rules/grade-rules.component';
import { AddActionComponent } from './add-action/add-action.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { AddSponsorshipComponent } from './add-sponsorship/add-sponsorship.component';
import { AddRollCallComponent } from './add-roll-call/add-roll-call.component';
import { PipesModule } from 'src/app/core/pipes/pipes.module';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';



@NgModule({
  declarations: [GradeRulesComponent, AddActionComponent, AddSponsorshipComponent, AddRollCallComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    PipesModule,
    MatStepperModule,
    MatProgressBarModule,
    HttpClientModule,
    MatIconModule,
    MatDialogModule
  ]
})
export class AddActionModule { }
