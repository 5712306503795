/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation, GraphQLResult } from "@aws-amplify/api-graphql";
import { Observable } from "zen-observable-ts";

export interface SubscriptionResponse<T> {
  value: GraphQLResult<T>;
}

export type CreateBillsInput = {
  id?: string | null;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
};

export type ModelBillsConditionInput = {
  congress?: ModelIntInput | null;
  billType?: ModelStringInput | null;
  billNumber?: ModelIntInput | null;
  billTitle?: ModelStringInput | null;
  sponsorName?: ModelStringInput | null;
  sponsorBioguideId?: ModelStringInput | null;
  introducedDate?: ModelStringInput | null;
  totalCosponsors?: ModelIntInput | null;
  publicDescription?: ModelStringInput | null;
  privateNotes?: ModelStringInput | null;
  gradingNotes?: ModelStringInput | null;
  displayWeb?: ModelBooleanInput | null;
  nusaPosition?: ModelIntInput | null;
  tags?: ModelStringInput | null;
  and?: Array<ModelBillsConditionInput | null> | null;
  or?: Array<ModelBillsConditionInput | null> | null;
  not?: ModelBillsConditionInput | null;
};

export type ModelIntInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null"
}

export type ModelStringInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type ModelSizeInput = {
  ne?: number | null;
  eq?: number | null;
  le?: number | null;
  lt?: number | null;
  ge?: number | null;
  gt?: number | null;
  between?: Array<number | null> | null;
};

export type ModelBooleanInput = {
  ne?: boolean | null;
  eq?: boolean | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
};

export type Bills = {
  __typename: "Bills";
  id?: string;
  congress?: number;
  billType?: string;
  billNumber?: number;
  billTitle?: string;
  sponsorName?: string;
  sponsorBioguideId?: string;
  introducedDate?: string;
  totalCosponsors?: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb?: boolean;
  nusaPosition?: number;
  tags?: Array<string | null> | null;
  action?: Array<Action | null> | null;
  amendments?: Array<Amendments | null> | null;
  createdAt?: string;
  updatedAt?: string;
};

export type Action = {
  __typename: "Action";
  id?: string;
  billId?: string;
  actionTitle?: string;
  chamber?: string;
  congress?: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType?: string;
  actionDate?: string;
  rollCallLink?: string | null;
  gradeRules?: Array<GradeRules | null> | null;
  createdAt?: string;
  updatedAt?: string;
};

export type GradeRules = {
  __typename: "GradeRules";
  gradeTitle?: string;
  gradeDescription?: string;
  category?: string;
  points?: string;
  goodBad?: string;
  applyToAction?: number;
};

export type Amendments = {
  __typename: "Amendments";
  id?: string;
  billId?: string;
  purpose?: string;
  gradingNotes?: string | null;
  amdtType?: string;
  amdtNumber?: number;
  actionDate?: string;
  sponsorName?: string;
  sponsorBioguideId?: string;
  action?: Array<Action | null> | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateBillsInput = {
  id: string;
  congress?: number | null;
  billType?: string | null;
  billNumber?: number | null;
  billTitle?: string | null;
  sponsorName?: string | null;
  sponsorBioguideId?: string | null;
  introducedDate?: string | null;
  totalCosponsors?: number | null;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb?: boolean | null;
  nusaPosition?: number | null;
  tags?: Array<string | null> | null;
};

export type DeleteBillsInput = {
  id?: string | null;
};

export type CreateAmendmentsInput = {
  id?: string | null;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
};

export type ModelAmendmentsConditionInput = {
  billId?: ModelIDInput | null;
  purpose?: ModelStringInput | null;
  gradingNotes?: ModelStringInput | null;
  amdtType?: ModelStringInput | null;
  amdtNumber?: ModelIntInput | null;
  actionDate?: ModelStringInput | null;
  sponsorName?: ModelStringInput | null;
  sponsorBioguideId?: ModelStringInput | null;
  and?: Array<ModelAmendmentsConditionInput | null> | null;
  or?: Array<ModelAmendmentsConditionInput | null> | null;
  not?: ModelAmendmentsConditionInput | null;
};

export type ModelIDInput = {
  ne?: string | null;
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  contains?: string | null;
  notContains?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
  attributeExists?: boolean | null;
  attributeType?: ModelAttributeTypes | null;
  size?: ModelSizeInput | null;
};

export type UpdateAmendmentsInput = {
  id: string;
  billId?: string | null;
  purpose?: string | null;
  gradingNotes?: string | null;
  amdtType?: string | null;
  amdtNumber?: number | null;
  actionDate?: string | null;
  sponsorName?: string | null;
  sponsorBioguideId?: string | null;
};

export type DeleteAmendmentsInput = {
  id?: string | null;
};

export type CreateActionInput = {
  id?: string | null;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<GradeRulesInput | null> | null;
};

export type GradeRulesInput = {
  gradeTitle: string;
  gradeDescription: string;
  category: string;
  points: string;
  goodBad: string;
  applyToAction: number;
};

export type ModelActionConditionInput = {
  billId?: ModelIDInput | null;
  actionTitle?: ModelStringInput | null;
  chamber?: ModelStringInput | null;
  congress?: ModelIntInput | null;
  sessionNumber?: ModelIntInput | null;
  rollNumber?: ModelIntInput | null;
  actionType?: ModelStringInput | null;
  actionDate?: ModelStringInput | null;
  rollCallLink?: ModelStringInput | null;
  and?: Array<ModelActionConditionInput | null> | null;
  or?: Array<ModelActionConditionInput | null> | null;
  not?: ModelActionConditionInput | null;
};

export type UpdateActionInput = {
  id: string;
  billId?: string | null;
  actionTitle?: string | null;
  chamber?: string | null;
  congress?: number | null;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType?: string | null;
  actionDate?: string | null;
  rollCallLink?: string | null;
  gradeRules?: Array<GradeRulesInput | null> | null;
};

export type DeleteActionInput = {
  id?: string | null;
};

export type CreateBillTypesInput = {
  id?: string | null;
  name: string;
  value: string;
  orderBy?: number | null;
};

export type ModelBillTypesConditionInput = {
  name?: ModelStringInput | null;
  value?: ModelStringInput | null;
  orderBy?: ModelIntInput | null;
  and?: Array<ModelBillTypesConditionInput | null> | null;
  or?: Array<ModelBillTypesConditionInput | null> | null;
  not?: ModelBillTypesConditionInput | null;
};

export type BillTypes = {
  __typename: "BillTypes";
  id?: string;
  name?: string;
  value?: string;
  orderBy?: number | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateBillTypesInput = {
  id: string;
  name?: string | null;
  value?: string | null;
  orderBy?: number | null;
};

export type DeleteBillTypesInput = {
  id?: string | null;
};

export type CreateCongressSessionsInput = {
  id?: string | null;
  term: number;
  isDefault: boolean;
};

export type ModelCongressSessionsConditionInput = {
  term?: ModelIntInput | null;
  isDefault?: ModelBooleanInput | null;
  and?: Array<ModelCongressSessionsConditionInput | null> | null;
  or?: Array<ModelCongressSessionsConditionInput | null> | null;
  not?: ModelCongressSessionsConditionInput | null;
};

export type CongressSessions = {
  __typename: "CongressSessions";
  id?: string;
  term?: number;
  isDefault?: boolean;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateCongressSessionsInput = {
  id: string;
  term?: number | null;
  isDefault?: boolean | null;
};

export type DeleteCongressSessionsInput = {
  id?: string | null;
};

export type CreateTagsLibraryInput = {
  id?: string | null;
  tagName: string;
};

export type ModelTagsLibraryConditionInput = {
  tagName?: ModelStringInput | null;
  and?: Array<ModelTagsLibraryConditionInput | null> | null;
  or?: Array<ModelTagsLibraryConditionInput | null> | null;
  not?: ModelTagsLibraryConditionInput | null;
};

export type TagsLibrary = {
  __typename: "TagsLibrary";
  id?: string;
  tagName?: string;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateTagsLibraryInput = {
  id: string;
  tagName?: string | null;
};

export type DeleteTagsLibraryInput = {
  id?: string | null;
};

export type CreateGradeCategoriesInput = {
  id?: string | null;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
};

export type ModelGradeCategoriesConditionInput = {
  shortName?: ModelStringInput | null;
  fullName?: ModelStringInput | null;
  isActive?: ModelBooleanInput | null;
  orderBy?: ModelIntInput | null;
  and?: Array<ModelGradeCategoriesConditionInput | null> | null;
  or?: Array<ModelGradeCategoriesConditionInput | null> | null;
  not?: ModelGradeCategoriesConditionInput | null;
};

export type GradeCategories = {
  __typename: "GradeCategories";
  id?: string;
  shortName?: string;
  fullName?: string;
  isActive?: boolean;
  orderBy?: number;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateGradeCategoriesInput = {
  id: string;
  shortName?: string | null;
  fullName?: string | null;
  isActive?: boolean | null;
  orderBy?: number | null;
};

export type DeleteGradeCategoriesInput = {
  id?: string | null;
};

export type CreateMemberActionsInput = {
  id?: string | null;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
};

export type ModelmemberActionsConditionInput = {
  bioguideId?: ModelIDInput | null;
  actionId?: ModelIDInput | null;
  billId?: ModelIDInput | null;
  actionTaken?: ModelIntInput | null;
  isGrading?: ModelIntInput | null;
  actionDate?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelmemberActionsConditionInput | null> | null;
  or?: Array<ModelmemberActionsConditionInput | null> | null;
  not?: ModelmemberActionsConditionInput | null;
};

export type memberActions = {
  __typename: "memberActions";
  id?: string;
  bioguideId?: string;
  actionId?: string;
  billId?: string;
  actionTaken?: number;
  isGrading?: number;
  actionDate?: string;
  notes?: string | null;
  createdAt?: string;
  updatedAt?: string;
};

export type UpdateMemberActionsInput = {
  id: string;
  bioguideId?: string | null;
  actionId?: string | null;
  billId?: string | null;
  actionTaken?: number | null;
  isGrading?: number | null;
  actionDate?: string | null;
  notes?: string | null;
};

export type DeleteMemberActionsInput = {
  id?: string | null;
};

export type ModelBillsFilterInput = {
  id?: ModelIDInput | null;
  congress?: ModelIntInput | null;
  billType?: ModelStringInput | null;
  billNumber?: ModelIntInput | null;
  billTitle?: ModelStringInput | null;
  sponsorName?: ModelStringInput | null;
  sponsorBioguideId?: ModelStringInput | null;
  introducedDate?: ModelStringInput | null;
  totalCosponsors?: ModelIntInput | null;
  publicDescription?: ModelStringInput | null;
  privateNotes?: ModelStringInput | null;
  gradingNotes?: ModelStringInput | null;
  displayWeb?: ModelBooleanInput | null;
  nusaPosition?: ModelIntInput | null;
  tags?: ModelStringInput | null;
  and?: Array<ModelBillsFilterInput | null> | null;
  or?: Array<ModelBillsFilterInput | null> | null;
  not?: ModelBillsFilterInput | null;
};

export type ModelBillsConnection = {
  __typename: "ModelBillsConnection";
  items?: Array<Bills | null> | null;
  nextToken?: string | null;
};

export type ModelAmendmentsFilterInput = {
  id?: ModelIDInput | null;
  billId?: ModelIDInput | null;
  purpose?: ModelStringInput | null;
  gradingNotes?: ModelStringInput | null;
  amdtType?: ModelStringInput | null;
  amdtNumber?: ModelIntInput | null;
  actionDate?: ModelStringInput | null;
  sponsorName?: ModelStringInput | null;
  sponsorBioguideId?: ModelStringInput | null;
  and?: Array<ModelAmendmentsFilterInput | null> | null;
  or?: Array<ModelAmendmentsFilterInput | null> | null;
  not?: ModelAmendmentsFilterInput | null;
};

export type ModelAmendmentsConnection = {
  __typename: "ModelAmendmentsConnection";
  items?: Array<Amendments | null> | null;
  nextToken?: string | null;
};

export type ModelActionFilterInput = {
  id?: ModelIDInput | null;
  billId?: ModelIDInput | null;
  actionTitle?: ModelStringInput | null;
  chamber?: ModelStringInput | null;
  congress?: ModelIntInput | null;
  sessionNumber?: ModelIntInput | null;
  rollNumber?: ModelIntInput | null;
  actionType?: ModelStringInput | null;
  actionDate?: ModelStringInput | null;
  rollCallLink?: ModelStringInput | null;
  and?: Array<ModelActionFilterInput | null> | null;
  or?: Array<ModelActionFilterInput | null> | null;
  not?: ModelActionFilterInput | null;
};

export type ModelActionConnection = {
  __typename: "ModelActionConnection";
  items?: Array<Action | null> | null;
  nextToken?: string | null;
};

export type ModelBillTypesFilterInput = {
  id?: ModelIDInput | null;
  name?: ModelStringInput | null;
  value?: ModelStringInput | null;
  orderBy?: ModelIntInput | null;
  and?: Array<ModelBillTypesFilterInput | null> | null;
  or?: Array<ModelBillTypesFilterInput | null> | null;
  not?: ModelBillTypesFilterInput | null;
};

export type ModelBillTypesConnection = {
  __typename: "ModelBillTypesConnection";
  items?: Array<BillTypes | null> | null;
  nextToken?: string | null;
};

export type ModelCongressSessionsFilterInput = {
  id?: ModelIDInput | null;
  term?: ModelIntInput | null;
  isDefault?: ModelBooleanInput | null;
  and?: Array<ModelCongressSessionsFilterInput | null> | null;
  or?: Array<ModelCongressSessionsFilterInput | null> | null;
  not?: ModelCongressSessionsFilterInput | null;
};

export type ModelCongressSessionsConnection = {
  __typename: "ModelCongressSessionsConnection";
  items?: Array<CongressSessions | null> | null;
  nextToken?: string | null;
};

export type ModelTagsLibraryFilterInput = {
  id?: ModelIDInput | null;
  tagName?: ModelStringInput | null;
  and?: Array<ModelTagsLibraryFilterInput | null> | null;
  or?: Array<ModelTagsLibraryFilterInput | null> | null;
  not?: ModelTagsLibraryFilterInput | null;
};

export type ModelTagsLibraryConnection = {
  __typename: "ModelTagsLibraryConnection";
  items?: Array<TagsLibrary | null> | null;
  nextToken?: string | null;
};

export type ModelGradeCategoriesFilterInput = {
  id?: ModelIDInput | null;
  shortName?: ModelStringInput | null;
  fullName?: ModelStringInput | null;
  isActive?: ModelBooleanInput | null;
  orderBy?: ModelIntInput | null;
  and?: Array<ModelGradeCategoriesFilterInput | null> | null;
  or?: Array<ModelGradeCategoriesFilterInput | null> | null;
  not?: ModelGradeCategoriesFilterInput | null;
};

export type ModelGradeCategoriesConnection = {
  __typename: "ModelGradeCategoriesConnection";
  items?: Array<GradeCategories | null> | null;
  nextToken?: string | null;
};

export type ModelmemberActionsFilterInput = {
  id?: ModelIDInput | null;
  bioguideId?: ModelIDInput | null;
  actionId?: ModelIDInput | null;
  billId?: ModelIDInput | null;
  actionTaken?: ModelIntInput | null;
  isGrading?: ModelIntInput | null;
  actionDate?: ModelStringInput | null;
  notes?: ModelStringInput | null;
  and?: Array<ModelmemberActionsFilterInput | null> | null;
  or?: Array<ModelmemberActionsFilterInput | null> | null;
  not?: ModelmemberActionsFilterInput | null;
};

export type ModelmemberActionsConnection = {
  __typename: "ModelmemberActionsConnection";
  items?: Array<memberActions | null> | null;
  nextToken?: string | null;
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type ModelStringKeyConditionInput = {
  eq?: string | null;
  le?: string | null;
  lt?: string | null;
  ge?: string | null;
  gt?: string | null;
  between?: Array<string | null> | null;
  beginsWith?: string | null;
};

export type CreateBillsMutation = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBillsMutation = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBillsMutation = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateAmendmentsMutation = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateAmendmentsMutation = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteAmendmentsMutation = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateActionMutation = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateActionMutation = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteActionMutation = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateBillTypesMutation = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateBillTypesMutation = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteBillTypesMutation = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type CreateCongressSessionsMutation = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type UpdateCongressSessionsMutation = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type DeleteCongressSessionsMutation = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type CreateTagsLibraryMutation = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type UpdateTagsLibraryMutation = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type DeleteTagsLibraryMutation = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type CreateGradeCategoriesMutation = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type UpdateGradeCategoriesMutation = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type DeleteGradeCategoriesMutation = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type CreateMemberActionsMutation = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type UpdateMemberActionsMutation = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type DeleteMemberActionsMutation = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type GetBillsQuery = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListBillssQuery = {
  __typename: "ModelBillsConnection";
  items?: Array<{
    __typename: "Bills";
    id: string;
    congress: number;
    billType: string;
    billNumber: number;
    billTitle: string;
    sponsorName: string;
    sponsorBioguideId: string;
    introducedDate: string;
    totalCosponsors: number;
    publicDescription?: string | null;
    privateNotes?: string | null;
    gradingNotes?: string | null;
    displayWeb: boolean;
    nusaPosition: number;
    tags?: Array<string | null> | null;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    amendments?: Array<{
      __typename: "Amendments";
      id: string;
      billId: string;
      purpose: string;
      gradingNotes?: string | null;
      amdtType: string;
      amdtNumber: number;
      actionDate: string;
      sponsorName: string;
      sponsorBioguideId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetAmendmentsQuery = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListAmendmentssQuery = {
  __typename: "ModelAmendmentsConnection";
  items?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetActionQuery = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type ListActionsQuery = {
  __typename: "ModelActionConnection";
  items?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetBillTypesQuery = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type ListBillTypessQuery = {
  __typename: "ModelBillTypesConnection";
  items?: Array<{
    __typename: "BillTypes";
    id: string;
    name: string;
    value: string;
    orderBy?: number | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetCongressSessionsQuery = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type ListCongressSessionssQuery = {
  __typename: "ModelCongressSessionsConnection";
  items?: Array<{
    __typename: "CongressSessions";
    id: string;
    term: number;
    isDefault: boolean;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetTagsLibraryQuery = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type ListTagsLibrarysQuery = {
  __typename: "ModelTagsLibraryConnection";
  items?: Array<{
    __typename: "TagsLibrary";
    id: string;
    tagName: string;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetGradeCategoriesQuery = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type ListGradeCategoriessQuery = {
  __typename: "ModelGradeCategoriesConnection";
  items?: Array<{
    __typename: "GradeCategories";
    id: string;
    shortName: string;
    fullName: string;
    isActive: boolean;
    orderBy: number;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type GetMemberActionsQuery = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type ListMemberActionssQuery = {
  __typename: "ModelmemberActionsConnection";
  items?: Array<{
    __typename: "memberActions";
    id: string;
    bioguideId: string;
    actionId: string;
    billId: string;
    actionTaken: number;
    isGrading: number;
    actionDate: string;
    notes?: string | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type BillsByCongressQuery = {
  __typename: "ModelBillsConnection";
  items?: Array<{
    __typename: "Bills";
    id: string;
    congress: number;
    billType: string;
    billNumber: number;
    billTitle: string;
    sponsorName: string;
    sponsorBioguideId: string;
    introducedDate: string;
    totalCosponsors: number;
    publicDescription?: string | null;
    privateNotes?: string | null;
    gradingNotes?: string | null;
    displayWeb: boolean;
    nusaPosition: number;
    tags?: Array<string | null> | null;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    amendments?: Array<{
      __typename: "Amendments";
      id: string;
      billId: string;
      purpose: string;
      gradingNotes?: string | null;
      amdtType: string;
      amdtNumber: number;
      actionDate: string;
      sponsorName: string;
      sponsorBioguideId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type BillsByBillTypeQuery = {
  __typename: "ModelBillsConnection";
  items?: Array<{
    __typename: "Bills";
    id: string;
    congress: number;
    billType: string;
    billNumber: number;
    billTitle: string;
    sponsorName: string;
    sponsorBioguideId: string;
    introducedDate: string;
    totalCosponsors: number;
    publicDescription?: string | null;
    privateNotes?: string | null;
    gradingNotes?: string | null;
    displayWeb: boolean;
    nusaPosition: number;
    tags?: Array<string | null> | null;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    amendments?: Array<{
      __typename: "Amendments";
      id: string;
      billId: string;
      purpose: string;
      gradingNotes?: string | null;
      amdtType: string;
      amdtNumber: number;
      actionDate: string;
      sponsorName: string;
      sponsorBioguideId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type BillsByTypeByCongressQuery = {
  __typename: "ModelBillsConnection";
  items?: Array<{
    __typename: "Bills";
    id: string;
    congress: number;
    billType: string;
    billNumber: number;
    billTitle: string;
    sponsorName: string;
    sponsorBioguideId: string;
    introducedDate: string;
    totalCosponsors: number;
    publicDescription?: string | null;
    privateNotes?: string | null;
    gradingNotes?: string | null;
    displayWeb: boolean;
    nusaPosition: number;
    tags?: Array<string | null> | null;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    amendments?: Array<{
      __typename: "Amendments";
      id: string;
      billId: string;
      purpose: string;
      gradingNotes?: string | null;
      amdtType: string;
      amdtNumber: number;
      actionDate: string;
      sponsorName: string;
      sponsorBioguideId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type BillsBySponsorQuery = {
  __typename: "ModelBillsConnection";
  items?: Array<{
    __typename: "Bills";
    id: string;
    congress: number;
    billType: string;
    billNumber: number;
    billTitle: string;
    sponsorName: string;
    sponsorBioguideId: string;
    introducedDate: string;
    totalCosponsors: number;
    publicDescription?: string | null;
    privateNotes?: string | null;
    gradingNotes?: string | null;
    displayWeb: boolean;
    nusaPosition: number;
    tags?: Array<string | null> | null;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    amendments?: Array<{
      __typename: "Amendments";
      id: string;
      billId: string;
      purpose: string;
      gradingNotes?: string | null;
      amdtType: string;
      amdtNumber: number;
      actionDate: string;
      sponsorName: string;
      sponsorBioguideId: string;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type BillActionsQuery = {
  __typename: "ModelActionConnection";
  items?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  nextToken?: string | null;
};

export type OnCreateBillsSubscription = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBillsSubscription = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBillsSubscription = {
  __typename: "Bills";
  id: string;
  congress: number;
  billType: string;
  billNumber: number;
  billTitle: string;
  sponsorName: string;
  sponsorBioguideId: string;
  introducedDate: string;
  totalCosponsors: number;
  publicDescription?: string | null;
  privateNotes?: string | null;
  gradingNotes?: string | null;
  displayWeb: boolean;
  nusaPosition: number;
  tags?: Array<string | null> | null;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  amendments?: Array<{
    __typename: "Amendments";
    id: string;
    billId: string;
    purpose: string;
    gradingNotes?: string | null;
    amdtType: string;
    amdtNumber: number;
    actionDate: string;
    sponsorName: string;
    sponsorBioguideId: string;
    action?: Array<{
      __typename: "Action";
      id: string;
      billId: string;
      actionTitle: string;
      chamber: string;
      congress: number;
      sessionNumber?: number | null;
      rollNumber?: number | null;
      actionType: string;
      actionDate: string;
      rollCallLink?: string | null;
      createdAt: string;
      updatedAt: string;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateAmendmentsSubscription = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateAmendmentsSubscription = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteAmendmentsSubscription = {
  __typename: "Amendments";
  id: string;
  billId: string;
  purpose: string;
  gradingNotes?: string | null;
  amdtType: string;
  amdtNumber: number;
  actionDate: string;
  sponsorName: string;
  sponsorBioguideId: string;
  action?: Array<{
    __typename: "Action";
    id: string;
    billId: string;
    actionTitle: string;
    chamber: string;
    congress: number;
    sessionNumber?: number | null;
    rollNumber?: number | null;
    actionType: string;
    actionDate: string;
    rollCallLink?: string | null;
    gradeRules?: Array<{
      __typename: "GradeRules";
      gradeTitle: string;
      gradeDescription: string;
      category: string;
      points: string;
      goodBad: string;
      applyToAction: number;
    } | null> | null;
    createdAt: string;
    updatedAt: string;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateActionSubscription = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateActionSubscription = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteActionSubscription = {
  __typename: "Action";
  id: string;
  billId: string;
  actionTitle: string;
  chamber: string;
  congress: number;
  sessionNumber?: number | null;
  rollNumber?: number | null;
  actionType: string;
  actionDate: string;
  rollCallLink?: string | null;
  gradeRules?: Array<{
    __typename: "GradeRules";
    gradeTitle: string;
    gradeDescription: string;
    category: string;
    points: string;
    goodBad: string;
    applyToAction: number;
  } | null> | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateBillTypesSubscription = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateBillTypesSubscription = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteBillTypesSubscription = {
  __typename: "BillTypes";
  id: string;
  name: string;
  value: string;
  orderBy?: number | null;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateCongressSessionsSubscription = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateCongressSessionsSubscription = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteCongressSessionsSubscription = {
  __typename: "CongressSessions";
  id: string;
  term: number;
  isDefault: boolean;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateTagsLibrarySubscription = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateTagsLibrarySubscription = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteTagsLibrarySubscription = {
  __typename: "TagsLibrary";
  id: string;
  tagName: string;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateGradeCategoriesSubscription = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateGradeCategoriesSubscription = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteGradeCategoriesSubscription = {
  __typename: "GradeCategories";
  id: string;
  shortName: string;
  fullName: string;
  isActive: boolean;
  orderBy: number;
  createdAt: string;
  updatedAt: string;
};

export type OnCreateMemberActionsSubscription = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnUpdateMemberActionsSubscription = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

export type OnDeleteMemberActionsSubscription = {
  __typename: "memberActions";
  id: string;
  bioguideId: string;
  actionId: string;
  billId: string;
  actionTaken: number;
  isGrading: number;
  actionDate: string;
  notes?: string | null;
  createdAt: string;
  updatedAt: string;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async CreateBills(
    input: CreateBillsInput,
    condition?: ModelBillsConditionInput
  ): Promise<CreateBillsMutation> {
    const statement = `mutation CreateBills($input: CreateBillsInput!, $condition: ModelBillsConditionInput) {
        createBills(input: $input, condition: $condition) {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBillsMutation>response.data.createBills;
  }
  async UpdateBills(
    input: UpdateBillsInput,
    condition?: ModelBillsConditionInput
  ): Promise<UpdateBillsMutation> {
    const statement = `mutation UpdateBills($input: UpdateBillsInput!, $condition: ModelBillsConditionInput) {
        updateBills(input: $input, condition: $condition) {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBillsMutation>response.data.updateBills;
  }
  async DeleteBills(
    input: DeleteBillsInput,
    condition?: ModelBillsConditionInput
  ): Promise<DeleteBillsMutation> {
    const statement = `mutation DeleteBills($input: DeleteBillsInput!, $condition: ModelBillsConditionInput) {
        deleteBills(input: $input, condition: $condition) {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBillsMutation>response.data.deleteBills;
  }
  async CreateAmendments(
    input: CreateAmendmentsInput,
    condition?: ModelAmendmentsConditionInput
  ): Promise<CreateAmendmentsMutation> {
    const statement = `mutation CreateAmendments($input: CreateAmendmentsInput!, $condition: ModelAmendmentsConditionInput) {
        createAmendments(input: $input, condition: $condition) {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateAmendmentsMutation>response.data.createAmendments;
  }
  async UpdateAmendments(
    input: UpdateAmendmentsInput,
    condition?: ModelAmendmentsConditionInput
  ): Promise<UpdateAmendmentsMutation> {
    const statement = `mutation UpdateAmendments($input: UpdateAmendmentsInput!, $condition: ModelAmendmentsConditionInput) {
        updateAmendments(input: $input, condition: $condition) {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateAmendmentsMutation>response.data.updateAmendments;
  }
  async DeleteAmendments(
    input: DeleteAmendmentsInput,
    condition?: ModelAmendmentsConditionInput
  ): Promise<DeleteAmendmentsMutation> {
    const statement = `mutation DeleteAmendments($input: DeleteAmendmentsInput!, $condition: ModelAmendmentsConditionInput) {
        deleteAmendments(input: $input, condition: $condition) {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteAmendmentsMutation>response.data.deleteAmendments;
  }
  async CreateAction(
    input: CreateActionInput,
    condition?: ModelActionConditionInput
  ): Promise<CreateActionMutation> {
    const statement = `mutation CreateAction($input: CreateActionInput!, $condition: ModelActionConditionInput) {
        createAction(input: $input, condition: $condition) {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateActionMutation>response.data.createAction;
  }
  async UpdateAction(
    input: UpdateActionInput,
    condition?: ModelActionConditionInput
  ): Promise<UpdateActionMutation> {
    const statement = `mutation UpdateAction($input: UpdateActionInput!, $condition: ModelActionConditionInput) {
        updateAction(input: $input, condition: $condition) {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateActionMutation>response.data.updateAction;
  }
  async DeleteAction(
    input: DeleteActionInput,
    condition?: ModelActionConditionInput
  ): Promise<DeleteActionMutation> {
    const statement = `mutation DeleteAction($input: DeleteActionInput!, $condition: ModelActionConditionInput) {
        deleteAction(input: $input, condition: $condition) {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteActionMutation>response.data.deleteAction;
  }
  async CreateBillTypes(
    input: CreateBillTypesInput,
    condition?: ModelBillTypesConditionInput
  ): Promise<CreateBillTypesMutation> {
    const statement = `mutation CreateBillTypes($input: CreateBillTypesInput!, $condition: ModelBillTypesConditionInput) {
        createBillTypes(input: $input, condition: $condition) {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateBillTypesMutation>response.data.createBillTypes;
  }
  async UpdateBillTypes(
    input: UpdateBillTypesInput,
    condition?: ModelBillTypesConditionInput
  ): Promise<UpdateBillTypesMutation> {
    const statement = `mutation UpdateBillTypes($input: UpdateBillTypesInput!, $condition: ModelBillTypesConditionInput) {
        updateBillTypes(input: $input, condition: $condition) {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateBillTypesMutation>response.data.updateBillTypes;
  }
  async DeleteBillTypes(
    input: DeleteBillTypesInput,
    condition?: ModelBillTypesConditionInput
  ): Promise<DeleteBillTypesMutation> {
    const statement = `mutation DeleteBillTypes($input: DeleteBillTypesInput!, $condition: ModelBillTypesConditionInput) {
        deleteBillTypes(input: $input, condition: $condition) {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteBillTypesMutation>response.data.deleteBillTypes;
  }
  async CreateCongressSessions(
    input: CreateCongressSessionsInput,
    condition?: ModelCongressSessionsConditionInput
  ): Promise<CreateCongressSessionsMutation> {
    const statement = `mutation CreateCongressSessions($input: CreateCongressSessionsInput!, $condition: ModelCongressSessionsConditionInput) {
        createCongressSessions(input: $input, condition: $condition) {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateCongressSessionsMutation>response.data.createCongressSessions;
  }
  async UpdateCongressSessions(
    input: UpdateCongressSessionsInput,
    condition?: ModelCongressSessionsConditionInput
  ): Promise<UpdateCongressSessionsMutation> {
    const statement = `mutation UpdateCongressSessions($input: UpdateCongressSessionsInput!, $condition: ModelCongressSessionsConditionInput) {
        updateCongressSessions(input: $input, condition: $condition) {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateCongressSessionsMutation>response.data.updateCongressSessions;
  }
  async DeleteCongressSessions(
    input: DeleteCongressSessionsInput,
    condition?: ModelCongressSessionsConditionInput
  ): Promise<DeleteCongressSessionsMutation> {
    const statement = `mutation DeleteCongressSessions($input: DeleteCongressSessionsInput!, $condition: ModelCongressSessionsConditionInput) {
        deleteCongressSessions(input: $input, condition: $condition) {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteCongressSessionsMutation>response.data.deleteCongressSessions;
  }
  async CreateTagsLibrary(
    input: CreateTagsLibraryInput,
    condition?: ModelTagsLibraryConditionInput
  ): Promise<CreateTagsLibraryMutation> {
    const statement = `mutation CreateTagsLibrary($input: CreateTagsLibraryInput!, $condition: ModelTagsLibraryConditionInput) {
        createTagsLibrary(input: $input, condition: $condition) {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateTagsLibraryMutation>response.data.createTagsLibrary;
  }
  async UpdateTagsLibrary(
    input: UpdateTagsLibraryInput,
    condition?: ModelTagsLibraryConditionInput
  ): Promise<UpdateTagsLibraryMutation> {
    const statement = `mutation UpdateTagsLibrary($input: UpdateTagsLibraryInput!, $condition: ModelTagsLibraryConditionInput) {
        updateTagsLibrary(input: $input, condition: $condition) {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateTagsLibraryMutation>response.data.updateTagsLibrary;
  }
  async DeleteTagsLibrary(
    input: DeleteTagsLibraryInput,
    condition?: ModelTagsLibraryConditionInput
  ): Promise<DeleteTagsLibraryMutation> {
    const statement = `mutation DeleteTagsLibrary($input: DeleteTagsLibraryInput!, $condition: ModelTagsLibraryConditionInput) {
        deleteTagsLibrary(input: $input, condition: $condition) {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteTagsLibraryMutation>response.data.deleteTagsLibrary;
  }
  async CreateGradeCategories(
    input: CreateGradeCategoriesInput,
    condition?: ModelGradeCategoriesConditionInput
  ): Promise<CreateGradeCategoriesMutation> {
    const statement = `mutation CreateGradeCategories($input: CreateGradeCategoriesInput!, $condition: ModelGradeCategoriesConditionInput) {
        createGradeCategories(input: $input, condition: $condition) {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateGradeCategoriesMutation>response.data.createGradeCategories;
  }
  async UpdateGradeCategories(
    input: UpdateGradeCategoriesInput,
    condition?: ModelGradeCategoriesConditionInput
  ): Promise<UpdateGradeCategoriesMutation> {
    const statement = `mutation UpdateGradeCategories($input: UpdateGradeCategoriesInput!, $condition: ModelGradeCategoriesConditionInput) {
        updateGradeCategories(input: $input, condition: $condition) {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateGradeCategoriesMutation>response.data.updateGradeCategories;
  }
  async DeleteGradeCategories(
    input: DeleteGradeCategoriesInput,
    condition?: ModelGradeCategoriesConditionInput
  ): Promise<DeleteGradeCategoriesMutation> {
    const statement = `mutation DeleteGradeCategories($input: DeleteGradeCategoriesInput!, $condition: ModelGradeCategoriesConditionInput) {
        deleteGradeCategories(input: $input, condition: $condition) {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteGradeCategoriesMutation>response.data.deleteGradeCategories;
  }
  async CreateMemberActions(
    input: CreateMemberActionsInput,
    condition?: ModelmemberActionsConditionInput
  ): Promise<CreateMemberActionsMutation> {
    const statement = `mutation CreateMemberActions($input: CreateMemberActionsInput!, $condition: ModelmemberActionsConditionInput) {
        createMemberActions(input: $input, condition: $condition) {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <CreateMemberActionsMutation>response.data.createMemberActions;
  }
  async UpdateMemberActions(
    input: UpdateMemberActionsInput,
    condition?: ModelmemberActionsConditionInput
  ): Promise<UpdateMemberActionsMutation> {
    const statement = `mutation UpdateMemberActions($input: UpdateMemberActionsInput!, $condition: ModelmemberActionsConditionInput) {
        updateMemberActions(input: $input, condition: $condition) {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <UpdateMemberActionsMutation>response.data.updateMemberActions;
  }
  async DeleteMemberActions(
    input: DeleteMemberActionsInput,
    condition?: ModelmemberActionsConditionInput
  ): Promise<DeleteMemberActionsMutation> {
    const statement = `mutation DeleteMemberActions($input: DeleteMemberActionsInput!, $condition: ModelmemberActionsConditionInput) {
        deleteMemberActions(input: $input, condition: $condition) {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      input
    };
    if (condition) {
      gqlAPIServiceArguments.condition = condition;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <DeleteMemberActionsMutation>response.data.deleteMemberActions;
  }
  async GetBills(id: string): Promise<GetBillsQuery> {
    const statement = `query GetBills($id: ID!) {
        getBills(id: $id) {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBillsQuery>response.data.getBills;
  }
  async ListBillss(
    filter?: ModelBillsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBillssQuery> {
    const statement = `query ListBillss($filter: ModelBillsFilterInput, $limit: Int, $nextToken: String) {
        listBillss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            congress
            billType
            billNumber
            billTitle
            sponsorName
            sponsorBioguideId
            introducedDate
            totalCosponsors
            publicDescription
            privateNotes
            gradingNotes
            displayWeb
            nusaPosition
            tags
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            amendments {
              __typename
              id
              billId
              purpose
              gradingNotes
              amdtType
              amdtNumber
              actionDate
              sponsorName
              sponsorBioguideId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBillssQuery>response.data.listBillss;
  }
  async GetAmendments(id: string): Promise<GetAmendmentsQuery> {
    const statement = `query GetAmendments($id: ID!) {
        getAmendments(id: $id) {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetAmendmentsQuery>response.data.getAmendments;
  }
  async ListAmendmentss(
    filter?: ModelAmendmentsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListAmendmentssQuery> {
    const statement = `query ListAmendmentss($filter: ModelAmendmentsFilterInput, $limit: Int, $nextToken: String) {
        listAmendmentss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListAmendmentssQuery>response.data.listAmendmentss;
  }
  async GetAction(id: string): Promise<GetActionQuery> {
    const statement = `query GetAction($id: ID!) {
        getAction(id: $id) {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetActionQuery>response.data.getAction;
  }
  async ListActions(
    filter?: ModelActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListActionsQuery> {
    const statement = `query ListActions($filter: ModelActionFilterInput, $limit: Int, $nextToken: String) {
        listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListActionsQuery>response.data.listActions;
  }
  async GetBillTypes(id: string): Promise<GetBillTypesQuery> {
    const statement = `query GetBillTypes($id: ID!) {
        getBillTypes(id: $id) {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetBillTypesQuery>response.data.getBillTypes;
  }
  async ListBillTypess(
    filter?: ModelBillTypesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListBillTypessQuery> {
    const statement = `query ListBillTypess($filter: ModelBillTypesFilterInput, $limit: Int, $nextToken: String) {
        listBillTypess(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            name
            value
            orderBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListBillTypessQuery>response.data.listBillTypess;
  }
  async GetCongressSessions(id: string): Promise<GetCongressSessionsQuery> {
    const statement = `query GetCongressSessions($id: ID!) {
        getCongressSessions(id: $id) {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetCongressSessionsQuery>response.data.getCongressSessions;
  }
  async ListCongressSessionss(
    filter?: ModelCongressSessionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListCongressSessionssQuery> {
    const statement = `query ListCongressSessionss($filter: ModelCongressSessionsFilterInput, $limit: Int, $nextToken: String) {
        listCongressSessionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            term
            isDefault
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListCongressSessionssQuery>response.data.listCongressSessionss;
  }
  async GetTagsLibrary(id: string): Promise<GetTagsLibraryQuery> {
    const statement = `query GetTagsLibrary($id: ID!) {
        getTagsLibrary(id: $id) {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetTagsLibraryQuery>response.data.getTagsLibrary;
  }
  async ListTagsLibrarys(
    filter?: ModelTagsLibraryFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListTagsLibrarysQuery> {
    const statement = `query ListTagsLibrarys($filter: ModelTagsLibraryFilterInput, $limit: Int, $nextToken: String) {
        listTagsLibrarys(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            tagName
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListTagsLibrarysQuery>response.data.listTagsLibrarys;
  }
  async GetGradeCategories(id: string): Promise<GetGradeCategoriesQuery> {
    const statement = `query GetGradeCategories($id: ID!) {
        getGradeCategories(id: $id) {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetGradeCategoriesQuery>response.data.getGradeCategories;
  }
  async ListGradeCategoriess(
    filter?: ModelGradeCategoriesFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListGradeCategoriessQuery> {
    const statement = `query ListGradeCategoriess($filter: ModelGradeCategoriesFilterInput, $limit: Int, $nextToken: String) {
        listGradeCategoriess(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            shortName
            fullName
            isActive
            orderBy
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListGradeCategoriessQuery>response.data.listGradeCategoriess;
  }
  async GetMemberActions(id: string): Promise<GetMemberActionsQuery> {
    const statement = `query GetMemberActions($id: ID!) {
        getMemberActions(id: $id) {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`;
    const gqlAPIServiceArguments: any = {
      id
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetMemberActionsQuery>response.data.getMemberActions;
  }
  async ListMemberActionss(
    filter?: ModelmemberActionsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<ListMemberActionssQuery> {
    const statement = `query ListMemberActionss($filter: ModelmemberActionsFilterInput, $limit: Int, $nextToken: String) {
        listMemberActionss(filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            bioguideId
            actionId
            billId
            actionTaken
            isGrading
            actionDate
            notes
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <ListMemberActionssQuery>response.data.listMemberActionss;
  }
  async BillsByCongress(
    congress?: number,
    sortDirection?: ModelSortDirection,
    filter?: ModelBillsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BillsByCongressQuery> {
    const statement = `query BillsByCongress($congress: Int, $sortDirection: ModelSortDirection, $filter: ModelBillsFilterInput, $limit: Int, $nextToken: String) {
        billsByCongress(congress: $congress, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            congress
            billType
            billNumber
            billTitle
            sponsorName
            sponsorBioguideId
            introducedDate
            totalCosponsors
            publicDescription
            privateNotes
            gradingNotes
            displayWeb
            nusaPosition
            tags
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            amendments {
              __typename
              id
              billId
              purpose
              gradingNotes
              amdtType
              amdtNumber
              actionDate
              sponsorName
              sponsorBioguideId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (congress) {
      gqlAPIServiceArguments.congress = congress;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillsByCongressQuery>response.data.billsByCongress;
  }
  async BillsByBillType(
    billType?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBillsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BillsByBillTypeQuery> {
    const statement = `query BillsByBillType($billType: String, $sortDirection: ModelSortDirection, $filter: ModelBillsFilterInput, $limit: Int, $nextToken: String) {
        billsByBillType(billType: $billType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            congress
            billType
            billNumber
            billTitle
            sponsorName
            sponsorBioguideId
            introducedDate
            totalCosponsors
            publicDescription
            privateNotes
            gradingNotes
            displayWeb
            nusaPosition
            tags
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            amendments {
              __typename
              id
              billId
              purpose
              gradingNotes
              amdtType
              amdtNumber
              actionDate
              sponsorName
              sponsorBioguideId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (billType) {
      gqlAPIServiceArguments.billType = billType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillsByBillTypeQuery>response.data.billsByBillType;
  }
  async BillsByTypeByCongress(
    congress?: number,
    billType?: ModelStringKeyConditionInput,
    sortDirection?: ModelSortDirection,
    filter?: ModelBillsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BillsByTypeByCongressQuery> {
    const statement = `query BillsByTypeByCongress($congress: Int, $billType: ModelStringKeyConditionInput, $sortDirection: ModelSortDirection, $filter: ModelBillsFilterInput, $limit: Int, $nextToken: String) {
        billsByTypeByCongress(congress: $congress, billType: $billType, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            congress
            billType
            billNumber
            billTitle
            sponsorName
            sponsorBioguideId
            introducedDate
            totalCosponsors
            publicDescription
            privateNotes
            gradingNotes
            displayWeb
            nusaPosition
            tags
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            amendments {
              __typename
              id
              billId
              purpose
              gradingNotes
              amdtType
              amdtNumber
              actionDate
              sponsorName
              sponsorBioguideId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (congress) {
      gqlAPIServiceArguments.congress = congress;
    }
    if (billType) {
      gqlAPIServiceArguments.billType = billType;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillsByTypeByCongressQuery>response.data.billsByTypeByCongress;
  }
  async BillsBySponsor(
    sponsorBioguideId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelBillsFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BillsBySponsorQuery> {
    const statement = `query BillsBySponsor($sponsorBioguideId: String, $sortDirection: ModelSortDirection, $filter: ModelBillsFilterInput, $limit: Int, $nextToken: String) {
        billsBySponsor(sponsorBioguideId: $sponsorBioguideId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            congress
            billType
            billNumber
            billTitle
            sponsorName
            sponsorBioguideId
            introducedDate
            totalCosponsors
            publicDescription
            privateNotes
            gradingNotes
            displayWeb
            nusaPosition
            tags
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            amendments {
              __typename
              id
              billId
              purpose
              gradingNotes
              amdtType
              amdtNumber
              actionDate
              sponsorName
              sponsorBioguideId
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (sponsorBioguideId) {
      gqlAPIServiceArguments.sponsorBioguideId = sponsorBioguideId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillsBySponsorQuery>response.data.billsBySponsor;
  }
  async BillActions(
    billId?: string,
    sortDirection?: ModelSortDirection,
    filter?: ModelActionFilterInput,
    limit?: number,
    nextToken?: string
  ): Promise<BillActionsQuery> {
    const statement = `query BillActions($billId: ID, $sortDirection: ModelSortDirection, $filter: ModelActionFilterInput, $limit: Int, $nextToken: String) {
        billActions(billId: $billId, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
          __typename
          items {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          nextToken
        }
      }`;
    const gqlAPIServiceArguments: any = {};
    if (billId) {
      gqlAPIServiceArguments.billId = billId;
    }
    if (sortDirection) {
      gqlAPIServiceArguments.sortDirection = sortDirection;
    }
    if (filter) {
      gqlAPIServiceArguments.filter = filter;
    }
    if (limit) {
      gqlAPIServiceArguments.limit = limit;
    }
    if (nextToken) {
      gqlAPIServiceArguments.nextToken = nextToken;
    }
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <BillActionsQuery>response.data.billActions;
  }
  OnCreateBillsListener: Observable<
    SubscriptionResponse<OnCreateBillsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBills {
        onCreateBills {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateBillsSubscription>>;

  OnUpdateBillsListener: Observable<
    SubscriptionResponse<OnUpdateBillsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBills {
        onUpdateBills {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateBillsSubscription>>;

  OnDeleteBillsListener: Observable<
    SubscriptionResponse<OnDeleteBillsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBills {
        onDeleteBills {
          __typename
          id
          congress
          billType
          billNumber
          billTitle
          sponsorName
          sponsorBioguideId
          introducedDate
          totalCosponsors
          publicDescription
          privateNotes
          gradingNotes
          displayWeb
          nusaPosition
          tags
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          amendments {
            __typename
            id
            billId
            purpose
            gradingNotes
            amdtType
            amdtNumber
            actionDate
            sponsorName
            sponsorBioguideId
            action {
              __typename
              id
              billId
              actionTitle
              chamber
              congress
              sessionNumber
              rollNumber
              actionType
              actionDate
              rollCallLink
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteBillsSubscription>>;

  OnCreateAmendmentsListener: Observable<
    SubscriptionResponse<OnCreateAmendmentsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAmendments {
        onCreateAmendments {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateAmendmentsSubscription>>;

  OnUpdateAmendmentsListener: Observable<
    SubscriptionResponse<OnUpdateAmendmentsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAmendments {
        onUpdateAmendments {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateAmendmentsSubscription>>;

  OnDeleteAmendmentsListener: Observable<
    SubscriptionResponse<OnDeleteAmendmentsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAmendments {
        onDeleteAmendments {
          __typename
          id
          billId
          purpose
          gradingNotes
          amdtType
          amdtNumber
          actionDate
          sponsorName
          sponsorBioguideId
          action {
            __typename
            id
            billId
            actionTitle
            chamber
            congress
            sessionNumber
            rollNumber
            actionType
            actionDate
            rollCallLink
            gradeRules {
              __typename
              gradeTitle
              gradeDescription
              category
              points
              goodBad
              applyToAction
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteAmendmentsSubscription>>;

  OnCreateActionListener: Observable<
    SubscriptionResponse<OnCreateActionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateAction {
        onCreateAction {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateActionSubscription>>;

  OnUpdateActionListener: Observable<
    SubscriptionResponse<OnUpdateActionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateAction {
        onUpdateAction {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateActionSubscription>>;

  OnDeleteActionListener: Observable<
    SubscriptionResponse<OnDeleteActionSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteAction {
        onDeleteAction {
          __typename
          id
          billId
          actionTitle
          chamber
          congress
          sessionNumber
          rollNumber
          actionType
          actionDate
          rollCallLink
          gradeRules {
            __typename
            gradeTitle
            gradeDescription
            category
            points
            goodBad
            applyToAction
          }
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteActionSubscription>>;

  OnCreateBillTypesListener: Observable<
    SubscriptionResponse<OnCreateBillTypesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateBillTypes {
        onCreateBillTypes {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateBillTypesSubscription>>;

  OnUpdateBillTypesListener: Observable<
    SubscriptionResponse<OnUpdateBillTypesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateBillTypes {
        onUpdateBillTypes {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateBillTypesSubscription>>;

  OnDeleteBillTypesListener: Observable<
    SubscriptionResponse<OnDeleteBillTypesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteBillTypes {
        onDeleteBillTypes {
          __typename
          id
          name
          value
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteBillTypesSubscription>>;

  OnCreateCongressSessionsListener: Observable<
    SubscriptionResponse<OnCreateCongressSessionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateCongressSessions {
        onCreateCongressSessions {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateCongressSessionsSubscription>>;

  OnUpdateCongressSessionsListener: Observable<
    SubscriptionResponse<OnUpdateCongressSessionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateCongressSessions {
        onUpdateCongressSessions {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateCongressSessionsSubscription>>;

  OnDeleteCongressSessionsListener: Observable<
    SubscriptionResponse<OnDeleteCongressSessionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteCongressSessions {
        onDeleteCongressSessions {
          __typename
          id
          term
          isDefault
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteCongressSessionsSubscription>>;

  OnCreateTagsLibraryListener: Observable<
    SubscriptionResponse<OnCreateTagsLibrarySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateTagsLibrary {
        onCreateTagsLibrary {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateTagsLibrarySubscription>>;

  OnUpdateTagsLibraryListener: Observable<
    SubscriptionResponse<OnUpdateTagsLibrarySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateTagsLibrary {
        onUpdateTagsLibrary {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateTagsLibrarySubscription>>;

  OnDeleteTagsLibraryListener: Observable<
    SubscriptionResponse<OnDeleteTagsLibrarySubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteTagsLibrary {
        onDeleteTagsLibrary {
          __typename
          id
          tagName
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteTagsLibrarySubscription>>;

  OnCreateGradeCategoriesListener: Observable<
    SubscriptionResponse<OnCreateGradeCategoriesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateGradeCategories {
        onCreateGradeCategories {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateGradeCategoriesSubscription>>;

  OnUpdateGradeCategoriesListener: Observable<
    SubscriptionResponse<OnUpdateGradeCategoriesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateGradeCategories {
        onUpdateGradeCategories {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateGradeCategoriesSubscription>>;

  OnDeleteGradeCategoriesListener: Observable<
    SubscriptionResponse<OnDeleteGradeCategoriesSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteGradeCategories {
        onDeleteGradeCategories {
          __typename
          id
          shortName
          fullName
          isActive
          orderBy
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteGradeCategoriesSubscription>>;

  OnCreateMemberActionsListener: Observable<
    SubscriptionResponse<OnCreateMemberActionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnCreateMemberActions {
        onCreateMemberActions {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnCreateMemberActionsSubscription>>;

  OnUpdateMemberActionsListener: Observable<
    SubscriptionResponse<OnUpdateMemberActionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnUpdateMemberActions {
        onUpdateMemberActions {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnUpdateMemberActionsSubscription>>;

  OnDeleteMemberActionsListener: Observable<
    SubscriptionResponse<OnDeleteMemberActionsSubscription>
  > = API.graphql(
    graphqlOperation(
      `subscription OnDeleteMemberActions {
        onDeleteMemberActions {
          __typename
          id
          bioguideId
          actionId
          billId
          actionTaken
          isGrading
          actionDate
          notes
          createdAt
          updatedAt
        }
      }`
    )
  ) as Observable<SubscriptionResponse<OnDeleteMemberActionsSubscription>>;
}
