import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { APIService } from 'src/app/API.service';
import { AddActionComponent } from '../../add-action/add-action/add-action.component';

@Component({
  selector: 'app-edit-bill',
  templateUrl: './edit-bill.component.html',
  styleUrls: ['./edit-bill.component.css']
})
export class EditBillComponent implements OnInit {

  @Input() item;
  @Output() saveEvent = new EventEmitter();
  billLink: string;
  chamber: string;

  edit = {
    billTitle: false,
    publicDescription: false,
    privateNotes: false,
    gradingNotes: false,
    displayWeb: false,
    nusaPosition: false,
    tags: false
  }

  constructor(public dialog: MatDialog, private api: APIService) { }

  ngOnInit() {
    if (this.item.billType == 'hr') {this.chamber="house-bill"};
    if (this.item.billType == 'hres') {this.chamber="house-resolution"};
    if (this.item.billType == 'hjres') {this.chamber="house-joint-resolution"};
    if (this.item.billType == 'hconres') {this.chamber="house-concurrent-resolution"};
    if (this.item.billType == 's') {this.chamber="senate-bill"};
    if (this.item.billType == 'sres') {this.chamber="senate-resolution"};
    if (this.item.billType == 'sjres') {this.chamber="senate-joint-resolution"};
    if (this.item.billType == 'sconres') {this.chamber="senate-concurrent-resolution"};
    this.billLink = `https://www.congress.gov/bill/${this.item.congress}-congress/${this.chamber}/${this.item.billNumber}`;
  }

  openDialog() {
    this.dialog.open(AddActionComponent, {
      width: '80%',
      height: '80%',
      data: { billData: this.item }
    });
  }

}
